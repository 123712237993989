import { GraphQLResponse } from '@maverick/http';
import { GetMenuGraphQL } from './graphql/Queries/Menu.graphql';
import { SitecoreClient } from '@maverick/ui';

export const MenuService = {
	GetMenu: async <T>(datasource: string, language: string): Promise<GraphQLResponse<T>> => {
		return SitecoreClient.ExecuteRequest<T>(GetMenuGraphQL, {
			datasource: datasource,
			language: language,
		});
	},
};
