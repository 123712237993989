import { SitecoreClient } from '@maverick/ui';
import { GraphQLResponse } from '@maverick/http';
import { gql } from 'graphql-request';

export interface RewardsModalLogo {
	item: {
		Logo: {
			src: string;
			alt: string;
		};
	};
}

export const LogoService = {
	GetLogo: async <T>(): Promise<GraphQLResponse<T>> => {
		return SitecoreClient.ExecuteRequest<T>(getLogoRequest, {
			datasource: `/sitecore/content/BBI/Outback/Home`,
		});
	},
};

const getLogoRequest = gql`
	query GetLogoHome($datasource: String!) {
		item(path: $datasource, language: "en") {
			Logo: field(name: "Logo Rewards Modal") {
				... on ImageField {
					src
					alt
				}
			}
		}
	}
`;
