import { GraphQLResponse } from '@maverick/http';
import { SitecoreClient } from '@maverick/ui';
import { GetTabNavigation } from './graphql/Queries/TabNavigation.graphql';

export const TabNavigationService = {
	GetTabNavigation: async <T>(datasource: string, language: string): Promise<GraphQLResponse<T>> => {
		return SitecoreClient.ExecuteRequest<T>(GetTabNavigation, {
			datasource: datasource,
			language: language,
		});
	},
};
