import { NavigationProps } from '@maverick/entity';
import { GetSitecoreFooterRoutes, GetSitecoreHeaderRoutes } from '@maverick/ui';
import { Config } from 'src/Config';

export type NavigationRendering = {
	dataSource: string;
	language: string;
	fields: {
		[NavigationField.Main]: { value: string };
		[NavigationField.Hamburger]: { value: string };
		[NavigationField.Featured]: { value: string };
		[NavigationField.Unfeatured]: { value: string };
	};
};

export enum NavigationField {
	Main = 'Main Items',
	Hamburger = 'Hamburguer Items',
	Featured = 'Featured Items',
	Unfeatured = 'Unfeatured Items',
}

const GetNavigationItems = async (rendering: NavigationRendering): Promise<NavigationProps> => {
	const headerRoutes = await GetSitecoreHeaderRoutes(rendering?.dataSource, rendering.language, Config.Brand);
	const footerRoutes = await GetSitecoreFooterRoutes(rendering?.dataSource, rendering.language, Config.Brand);
	return { ...headerRoutes, ...footerRoutes };
};

export const NavigationRoutes = {
	Routes: GetNavigationItems,
};
