import { GraphQLResponse } from '@maverick/http';
import { SitecoreClient } from '@maverick/ui';
import { GetMenuNavigation } from './graphql/Queries/MenuNavigation.graphql';

export const MenuNavigationService = {
	GetMenuNavigation: async <T>(datasource: string, language: string): Promise<GraphQLResponse<T>> => {
		return SitecoreClient.ExecuteRequest<T>(GetMenuNavigation, {
			datasource: datasource,
			language: language,
		});
	},
};
